
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import { BaseIcon, BaseButton, BaseTextInput } from '@/lib/components';
import { Consult, ExternalPatientReference, Patient } from '@/models';
import { UHB_NHS_NUMBER_KEY } from '@/constants';
import { formattedNHSNumber } from '@/lib/helpers/nhs-number.helper';
import BaseTextarea from '@/lib/components/Textarea/BaseTextarea.vue';
import { getDobFromISOString } from '@/helpers/patient.helper';
import BaseModal from './BaseModal.vue';

@Options({
  props: {
    consult: {
      type: Object,
      default: () => ({})
    },
    patient: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    BaseTextarea,
    BaseIcon,
    BaseModal,
    BaseButton,
    BaseTextInput
  }
})
export default class RejectReasonModal extends Vue {
  consult!: Consult;
  patient!: Patient;
  loading = false;

  errors: { [key: string]: string[] } = {};

  // Patient MRN
  patient_mrn_at_clinic_owner? = '';

  get patientNHSNumber(): string {
    if (this.patient && this.patient.external_patient_references) {
      const nhsEpr = this.patient.external_patient_references.find((epr: ExternalPatientReference) =>
        epr.type ? epr.type.key === UHB_NHS_NUMBER_KEY : false
      );

      if (nhsEpr && nhsEpr.value) {
        return formattedNHSNumber(nhsEpr.value);
      }
    }
    return this.$t('platform.common.none').toString();
  }

  get today() {
    return dayjs().format('YYYY-MM-DD');
  }

  close() {
    this.$emit('close');
  }

  formatDob(dob: string) {
    return this.$d(getDobFromISOString(dob), 'short');
  }
}
